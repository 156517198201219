import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'next-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#EAECF1',
    paddingTop: 40,
    paddingBottom: 50,
    [theme.breakpoints.up('md')]: {
      paddingTop: 80,
      paddingBottom: 80,
    },
  },
  heading: {
    fontSize: 34,
    color: 'black',
    marginBottom: 19,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
      lineHeight: '57px',
    },
  },
  subHeading: {
    fontSize: 16,
    color: '#58657C',
    width: '100%',
    textAlign: 'center',
    fontFamily: 'CircularStd-Book',
    [theme.breakpoints.up('md')]: {
      fontSize: 28,
      lineHeight: '42px',
      maxWidth: 854,
      margin: '0 auto',
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 44,
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      marginBottom: 58,
    },
  },
  featuresWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridRowGap: 13,
    gridColumnGap: 18,
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridRowGap: 40,
      gridColumnGap: 40,
    },
  },
  featureItem: {
    backgroundColor: '#fff',
    borderRadius: 12,
    overflow: 'hidden',
  },
  featureItemImageWrapper: {
    display: 'flex',
    height: 105,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [theme.breakpoints.up('sm')]: {
      height: 150,
    },
    [theme.breakpoints.up('md')]: {
      height: 235,
    },
  },
  featureItemImage: {
    height: 'auto',
    width: 80,
    [theme.breakpoints.up('md')]: {
      width: 80,
    },
    [theme.breakpoints.up('lg')]: {
      width: 100,
    },
  },
  featureItemHeading: {
    fontSize: 12,
    marginBottom: 11,
    color: '#000',
    [theme.breakpoints.up('md')]: {
      marginBottom: 8,
      fontSize: 24,
    },
  },
  featureItemContentWrapper: {
    padding: '8px 12px',
    minHeight: 53,
    [theme.breakpoints.up('md')]: {
      padding: '12px 20px',
      minHeight: 118,
    },
  },
}));

const FeatureItem = ({ image, heading, classes }) => {
  return (
    <div className={classes.featureItem}>
      <div
        className={classes.featureItemImageWrapper}
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className={classes.featureItemContentWrapper}>
        <Typography className={classes.featureItemHeading}>{heading}</Typography>
      </div>
    </div>
  );
};

let features = [];
for (let i = 1; i <= 5; i++) {
  features.push({
    image: `/static/images/industry-${i}.jpg`,
    heading: `industry-${i}`,
  });
}
const Industries = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <div className={classes.root}>
      <div className={classes.item}>
        <div className="container">
          <div className={classes.contentWrapper}>
            <Typography variant="h2" className={classes.heading}>
              {t('industries-heading')}
            </Typography>
            <Typography className={classes.subHeading}>{t('industries-desc')}</Typography>
          </div>
          <div className={classes.featuresWrapper}>
            {features.map(({ image, heading }, i) => (
              <FeatureItem key={i} classes={classes} image={image} heading={t(heading)} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Industries;
