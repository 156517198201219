import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Header from '@components/Header';
import Footer from '@components/Footer';
import LandingIntroduction from '@components/LandingIntroduction';
import Features from '@components/Features';
import SubFeatures from '@components/SubFeatures';
import Industries from '@components/Industries';
import Clients from '@components/Clients';
import PlanBanner from '@components/PlanBanner';
import PlanSection from '@components/PlanSection';
import _get from 'lodash/get';
import request from '@utils/request';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import LazyLoad from 'react-lazyload';

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    paddingTop: 58,
  },
});

let features = [];
for (let i = 1; i <= 8; i++) {
  features.push({
    image: `/static/images/subfeature-${i}.svg`,
    heading: `subfeature-${i}`,
    description: `subfeature-${i}-desc`,
  });
}

const Index = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <LandingIntroduction />
      <Features />
      <LazyLoad>
        <SubFeatures features={features} />
      </LazyLoad>
      <LazyLoad>
        <Industries />
      </LazyLoad>
      <LazyLoad>
        <Clients />
      </LazyLoad>
      <LazyLoad>
        <PlanBanner />
      </LazyLoad>
      <LazyLoad>
        <PlanSection />
      </LazyLoad>
      <Footer />
    </div>
  );
};

export const getServerSideProps = async ({ locale, ...props }) => {
  const host = _get(props, 'req.headers.host', '');
  const subdomain = host ? host.split('.')[0] : '';
  let redirect = false;
  let webStore = null;
  if (subdomain && subdomain !== 'dev' && subdomain !== 'sellonlive') {
    webStore = await request(`${process.env.NEXT_PUBLIC_API_SERVER}/store/${subdomain}`, {
      method: 'GET',
    }).then(res => {
      return _get(res, 'data.data', {});
    });
    if (_get(webStore, 'subdomain', false)) {
      redirect = {
        destination: '/store',
      };
    }
  }

  return {
    redirect,
    props: {
      subdomain,
      webStore,
      locale,
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
};
export default Index;
