import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'next-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    paddingTop: 40,
    paddingBottom: 50,
    [theme.breakpoints.up('md')]: {
      paddingTop: 80,
      paddingBottom: 80,
    },
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  contentWrapper: {
    width: 450,
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      flex: 1,
      width: 600,
      margin: 'initial',
    },
  },
  heading: {
    fontSize: 34,
    marginBottom: 22,
    color: 'black',
    lineHeight: '40px',
    [theme.breakpoints.up('md')]: {
      marginBottom: 27,
      fontSize: 48,
      lineHeight: '57px',
    },
  },
  description: {
    color: 'black',
    fontSize: 20,
    lineHeight: '25px',
    marginBottom: 24,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginBottom: 16,
      fontSize: 28,
      lineHeight: '42px',
      textAlign: 'left',
    },
  },
  benefitWrapper: {
    marginBottom: 34,
  },
  benefit: {
    fontSize: 18,
    lineHeight: '25px',
    color: '#31415E',
    fontFamily: 'CircularStd-Book',
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
      lineHeight: '36px',
    },
  },
  registerButton: {
    backgroundColor: '#076BD1',
    borderRadius: 40,
    height: 38,
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 60,
      paddingRight: 60,
      height: 55,
    },
  },
  registerLabel: {
    color: '#fff',
    padding: '12px 36px',
    fontSize: 16,
    lineHeight: '19px',
    position: 'relative',
    top: 10,
    [theme.breakpoints.up('md')]: {
      top: 14,
      fontSize: 20,
      lineHeight: '24px',
    },
  },
  divider: {
    height: 1,
    backgroundColor: '#58657C',
    width: '100%',
    margin: '38px 0 27px 0',
    [theme.breakpoints.up('md')]: {
      margin: '40px 0',
    },
  },
  graphicWrapper: {
    flex: 1,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      paddingRight: 100,
    },
  },
  graphicMobileWrapper: {
    marginTop: 49,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  socialWrapper: {
    display: 'flex',
    marginBottom: 0,
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      marginBottom: 18,
      justifyContent: 'flex-start',
    },
  },
  socialLink: { marginRight: 35 },
  socialIcon: {
    width: 51,
    [theme.breakpoints.up('md')]: {
      width: 58,
    },
  },
  planImage: {
    width: '100%',
  },
}));

const PlanSection = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation('common');
  return (
    <div className={classes.root}>
      <div className={['container', classes.contentContainer].join(' ')}>
        <div className={classes.graphicWrapper}>
          <img
            src={
              i18n.language === 'cn'
                ? '/static/images/plan-section-cn.svg'
                : '/static/images/plan-section-en.png'
            }
            className={classes.planImage}
          />
        </div>
        <div className={classes.contentWrapper}>
          <Typography variant="h1" component="h2" className={classes.heading}>
            {t('plan-section-heading')}
          </Typography>
          <div className={classes.benefitWrapper}>
            <ul>
              <li>
                <Typography variant="body1" className={classes.benefit}>
                  {t('plan-section-benefit-1')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" className={classes.benefit}>
                  {t('plan-section-benefit-2')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" className={classes.benefit}>
                  {t('plan-section-benefit-3')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" className={classes.benefit}>
                  {t('plan-section-benefit-4')}
                </Typography>
              </li>
            </ul>
          </div>
          <a
            href="https://api.whatsapp.com/send/?phone=60162433083&text=你好，想知道有关抓单系统的详情。"
            className={classes.registerButton}
          >
            <span className={classes.registerLabel}>{t('plan-section-register-btn')}</span>
          </a>
          <div className={classes.graphicMobileWrapper}>
            <img
              src={
                i18n.language === 'cn'
                  ? '/static/images/plan-section-cn.svg'
                  : '/static/images/plan-section-en.png'
              }
              className={classes.planImage}
            />
          </div>
          <div className={classes.divider}></div>
          <Typography className={classes.description}>{t('plan-section-question')}</Typography>
          <div className={classes.socialWrapper}>
            <a
              target="_blank"
              href="https://api.whatsapp.com/send/?phone=60162433083&text=你好，想知道有关抓单系统的详情。"
              className={classes.socialLink}
              rel="noreferrer"
            >
              <img src="/static/images/icon-whatsapp.svg" className={classes.socialIcon} />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/sellonlivetech"
              className={classes.socialLink}
              rel="noreferrer"
            >
              <img src="/static/images/icon-facebook.svg" className={classes.socialIcon} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanSection;
