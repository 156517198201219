import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import Slider from 'react-slick';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundColor: '#fff',
    paddingTop: 40,
    paddingBottom: 50,
    [theme.breakpoints.up('md')]: {
      paddingTop: 80,
      paddingBottom: 80,
    },
  },
  heading: {
    fontSize: 34,
    color: 'black',
    marginBottom: 19,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
      lineHeight: '57px',
    },
  },
  subHeading: {
    fontSize: 16,
    color: '#58657C',
    width: '100%',
    textAlign: 'center',
    fontFamily: 'CircularStd-Book',
    [theme.breakpoints.up('md')]: {
      fontSize: 28,
      lineHeight: '42px',
      maxWidth: 854,
      margin: '0 auto',
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 44,
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      marginBottom: 58,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  contentContainer: {
    zIndex: 2,
    padding: 0,
  },
  backgroundDeco1: {
    position: 'absolute',
    top: 150,
    left: -250,
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      left: -50,
    },
  },
  backgroundDeco2: {
    position: 'absolute',
    bottom: 100,
    right: -220,
    zIndex: 1,
  },
  '@global': {
    '.slick-slide img': {
      padding: '0 10px',
      width: '100%',
    },
    '.slick-prev:before': {
      backgroundImage: 'url(/static/images/left-arrow.svg)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: 15,
      height: 30,
      display: 'block',
      content: '',
      color: 'transparent',
      right: 50,
      position: 'relative',
    },
    '.slick-next:before': {
      backgroundImage: 'url(/static/images/right-arrow.svg)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: 15,
      height: 30,
      display: 'block',
      color: 'transparent',
      left: 50,
      position: 'relative',
    },
  },
}));

const FeatureItem = ({ image, classes }) => {
  return (
    <div className={classes.featureItem}>
      <img className={classes.featureItemImage} src={image} />
    </div>
  );
};

let features = [];
for (let i = 1; i <= 12; i++) {
  features.push({
    image: `/static/images/client-${i}.png`,
  });
}
const Clients = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          autoplaySpeed: 4000,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };

  return (
    <div className={classes.root}>
      <div className={['container', classes.contentContainer].join(' ')}>
        <div className={classes.contentWrapper}>
          <Typography variant="h2" className={classes.heading}>
            {t('clients-heading')}
          </Typography>
          <Typography className={classes.subHeading}>{t('clients-desc')}</Typography>
        </div>
        <Slider {...settings}>
          {features.map(({ image }, i) => (
            <FeatureItem key={i} classes={classes} image={image} />
          ))}
        </Slider>
      </div>
      <img src="/static/images/backgroundDeco.png" className={classes.backgroundDeco1} />
      <img src="/static/images/backgroundDeco.png" className={classes.backgroundDeco2} />
    </div>
  );
};

export default Clients;
