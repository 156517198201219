import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'next-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    paddingTop: 40,
    paddingBottom: 50,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: 120,
      paddingBottom: 90,
    },
  },
  heading: {
    fontSize: 34,
    color: 'black',
    marginBottom: 19,
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
      lineHeight: '57px',
    },
  },
  subHeading: {
    fontSize: 16,
    color: '#58657C',
    width: '100%',
    fontFamily: 'CircularStd-Book',
    [theme.breakpoints.up('md')]: {
      fontSize: 28,
      lineHeight: '42px',
      maxWidth: 854,
      margin: '0 auto',
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 72,
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      marginBottom: 131,
    },
  },
  featuresWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, calc(50% - 10px))',
    gridRowGap: 15,
    gridColumnGap: 20,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, calc(50% - 10px))',
      gridRowGap: 15,
      gridColumnGap: 20,
    },
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, calc(33% - 30px))',
      gridRowGap: 40,
      gridColumnGap: 50,
    },
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, calc(33% - 60px))',
      gridRowGap: 40,
      gridColumnGap: 90,
    },
  },
  featureItem: {
    width: '100%',
    margin: '0 auto 42px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 285,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 348,
      marginBottom: 0,
    },
  },
  featureItemImageWrapper: {
    marginBottom: 19,
    height: 100,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      height: 140,
      alignItems: 'center',
      marginBottom: 20,
    },
  },
  featureItemImage: {
    height: 'auto',
    maxWidth: 104,
    [theme.breakpoints.up('md')]: {
      maxWidth: 150,
    },
  },
  featureItemHeading: {
    fontSize: 16,
    marginBottom: 12,
    color: 'black',
    position: 'relative',
    lineHeight: '20px',
    [theme.breakpoints.up('md')]: {
      marginBottom: 21,
      fontSize: 28,
      lineHeight: '34px',
    },
  },
  featureItemDescription: {
    fontSize: 12,
    fontFamily: 'CircularStd-Book',
    color: '#31415E',
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      lineHeight: '26px',
    },
  },
}));

const FeatureItem = ({ image, heading, description, classes }) => {
  return (
    <div className={classes.featureItem}>
      <div className={classes.featureItemImageWrapper}>
        <img className={classes.featureItemImage} src={image} />
      </div>
      <Typography className={classes.featureItemHeading}>
        <b>{heading}</b>
      </Typography>
      <Typography className={classes.featureItemDescription}>{description}</Typography>
    </div>
  );
};

let features = [];
for (let i = 1; i <= 12; i++) {
  features.push({
    image: `/static/images/feature-${i}.svg`,
    heading: `feature-${i}`,
    description: `feature-${i}-desc`,
  });
}
const Features = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <div className={classes.root}>
      <div className={classes.item}>
        <div className="container">
          <div className={classes.contentWrapper}>
            <Typography variant="h2" className={classes.heading}>
              {t('features-heading')}
            </Typography>
            <Typography className={classes.subHeading}>{t('features-desc')}</Typography>
          </div>
          <div className={classes.featuresWrapper}>
            {features.map(({ image, heading, description }, i) => (
              <FeatureItem
                key={i}
                classes={classes}
                image={image}
                heading={t(heading)}
                description={t(description)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
